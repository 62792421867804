<!-- eslint-disable vue/no-v-html -->
<template>
  <v-card
    style="cursor: pointer;"
    @click="goUrl()"
  >
    <v-card-text>
      <span
        style="font-size: 20px;"
      >
        <b>
          {{ $t('dashboard.cardMarkups') }}
        </b>
      </span>
      <br />
      <span style="font-size: 14px;">
        {{ $t('dashboard.cardMarkups1') }}
      </span>

      <!--<span class="text-sm font-weight-semibold mb-1">
        Revenue Report
      </span>
      <v-spacer></v-spacer>
      <v-chip
        class="v-chip-light-bg secondary--text font-weight-semibold"
        small
      >
        This Year
      </v-chip>-->
    </v-card-text>

    <!-- Chart -->
    <vue-apex-charts
      height="250px"
      :options="chartOptions"
      :series="chartData"
    ></vue-apex-charts>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
// eslint-disable-next-line object-curly-newline
import {
  mdiAccountOutline,
  mdiCurrencyUsd,
  mdiTrendingUp,
  mdiDotsVertical,
  mdiLabelOutline,
} from '@mdi/js'

export default {
  components: {
    VueApexCharts: () => import('vue-apexcharts'),
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    user: {
      type: Object,
    },
  },
  data() {
    return {
      icons: {
        mdiAccountOutline,
        mdiCurrencyUsd,
        mdiTrendingUp,
        mdiDotsVertical,
        mdiLabelOutline,
      },
      chartOptions: {
        chart: {
          stacked: true,
          type: 'bar',
          toolbar: { show: false },
        },
        grid: {
          yaxis: {
            lines: { show: false },
          },
        },
        xaxis: {
          categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
          labels: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
        },
        legend: {
          markers: {
            radius: 15,
          },
          offsetY: 8,
        },
        stroke: {
          width: 2,
          colors: ['#fff'],
        },
        dataLabels: {
          enabled: false,
        },
        colors: [this.$vuetify.theme.currentTheme.success, this.$vuetify.theme.currentTheme.secondary],
        plotOptions: {
          bar: {
            columnWidth: '50%',
            borderRadius: 8,
            startingShape: 'rounded',
            endingShape: 'rounded',
          },
        },
        yaxis: {
          labels: {
            show: false,
          },
        },
      },
      chartData: [
        {
          name: this.$t('menu.markups'),
          data: [95, 177, 284, 256, 105, 63, 168, 218, 72],
        },
        {
          name: this.$t('lbl.precioNeto'),
          data: [-145, -80, -60, -180, -100, -60, -85, -75, -100],
        },
      ],
    }
  },
  computed: {
    ...mapState({
      chatBotRead: state => state.app.chatBotRead,
    }),
  },
  methods: {
    goUrl() {
      this.$router.push({ name: 'markups-list' })
    },
  },
}
</script>
