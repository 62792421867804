<template>
  <v-card class="greeting-card">
    <v-row class="ma-0 pa-0">
      <v-col cols="8">
        <v-card-title class="text-no-wrap pt-1 ps-2">
          {{ $t('dashboard.sellerDestacadoMonth') }} {{ user.name }} {{ user.apellidos }}! 🥳
        </v-card-title>
        <v-card-subtitle class="text-no-wrap ps-2">
          {{ $t('dashboard.sellerDestacadoMonth1') }}
        </v-card-subtitle>
        <v-card-text class="d-flex align-center mt-2 pb-2 ps-2">
          <div>
            <p class="text-xl font-weight-semibold primary--text mb-2">
              {{ salesMonthPreview.sale | currency(salesMonthPreview.symbol_base) }}
              <br />
              <span
                class="pt-0 mt-0"
                style="font-size: 12px; color: gray"
              >
                {{ `${salesMonthPreview.pc_all}%` }} {{ $t('dashboard.sellerDestacadoMonth2') }}
              </span>
            </p>

            <v-btn
              small
              color="primary"
            >
              {{ $t('dashboard.sellerDestacadoMonth3') }}
            </v-btn>
          </div>
        </v-card-text>
      </v-col>

      <v-col cols="4">
        <v-img
          contain
          height="180"
          width="159"
          :src="require(`@/assets/images/misc/triangle-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
          class="greeting-card-bg"
        ></v-img>
        <v-img
          contain
          height="108"
          max-width="83"
          class="greeting-card-trophy"
          src="@/assets/images/misc/trophy.png"
        ></v-img>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
// eslint-disable-next-line object-curly-newline
import {
  mdiAccountOutline,
  mdiCurrencyUsd,
  mdiTrendingUp,
  mdiDotsVertical,
  mdiLabelOutline,
} from '@mdi/js'
// eslint-disable-next-line import/no-unresolved
import { addAlpha } from '@core/utils'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    user: {
      type: Object,
    },
  },
  data() {
    return {
      icons: {
        mdiAccountOutline,
        mdiCurrencyUsd,
        mdiTrendingUp,
        mdiDotsVertical,
        mdiLabelOutline,
      },
      chartOptions: {
        labels: [this.$t('menu.hotels'), this.$t('menu.cars'), this.$t('menu.flights')],
        colors: [
          this.$vuetify.theme.currentTheme.primary,
          addAlpha(this.$vuetify.theme.currentTheme.primary, 0.3),
          addAlpha(this.$vuetify.theme.currentTheme.secondary, 0.1),
        ],
        chart: {
          type: 'donut',
          sparkline: {
            enabled: true,
          },
        },

        plotOptions: { },
      },
      chartData: [],
    }
  },
  computed: {
    ...mapState({
      salesMonthPreview: state => state.app.salesMonthPreview,
    }),
  },
}
</script>
<style lang="scss" scoped>
.greeting-card {
  position: relative;
  .greeting-card-bg {
    position: absolute;
    bottom: 0;
    right: 0;
  }
  .greeting-card-trophy {
    position: absolute;
    bottom: 10%;
    right: 8%;
  }
}
// rtl
.v-application {
  &.v-application--is-rtl {
    .greeting-card-bg {
      right: initial;
      left: 0;
      transform: rotateY(180deg);
    }
    .greeting-card-trophy {
      left: 8%;
      right: initial;
    }
  }
}
</style>
