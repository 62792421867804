<!-- eslint-disable vue/no-v-html -->
<template>
  <v-col
    v-if="afiliadoPendient.id && cardsDashboard.includes('afiliado-pendients')"
    cols="4"
  >
    <AfiliadoPendiente />
  </v-col>
</template>

<script>
import { mapState } from 'vuex'
// eslint-disable-next-line object-curly-newline
import {
  mdiAccountOutline,
  mdiCurrencyUsd,
  mdiTrendingUp,
  mdiDotsVertical,
  mdiLabelOutline,
} from '@mdi/js'
import AfiliadoPendiente from './AfiliadoPendiente.vue'

export default {
  components: {
    AfiliadoPendiente,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    user: {
      type: Object,
    },
  },
  data() {
    return {
      // eslint-disable-next-line import/no-unresolved
      img: require('@/assets/img/translate/translate1.svg'),
      imgs: [],
      icons: {
        mdiAccountOutline,
        mdiCurrencyUsd,
        mdiTrendingUp,
        mdiDotsVertical,
        mdiLabelOutline,
      },
    }
  },
  computed: {
    ...mapState({
      cardsDashboard: state => state.app.cardsDashboard,
      afiliadoPendient: state => state.app.afiliadoPendient,
    }),
  },
}
</script>
