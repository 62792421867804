<template>
  <v-card v-if="!isLoading && salesMonth.amountHotels > 0">
    <v-card-text class="align-start">
      <span style="font-size: 20px;">
        <b>{{ $t('dashboard.salesByContrate') }}: {{ $t('lbl.hotel') }}</b>
      </span>
      <br />
      <span style="font-size: 14px;">{{ $t('dashboard.salesByContrate1') }}</span>
      <!--<v-spacer></v-spacer>

      <v-btn
        icon
        small
        class="me-n3 mt-n2"
      >
        <v-icon>
          {{ icons.mdiDotsVertical }}
        </v-icon>
      </v-btn>-->
    </v-card-text>

    <v-card-text class="d-flex align-center justify-space-between pb-1 pt-5">
      <div>
        <p class="text-4xl font-weight-semibold text--primary mb-2">
          {{ salesMonth.amountHotels | currency(salesMonth.symbol_base) }}
        </p>
        <span class="text-base">{{ $t('dashboard.salesOverview') }}</span>
      </div>

      <div class="chart-wrapper">
        <apexchart
          :options="chartOptions"
          :series="series"
          :color="$vuetify.theme.themes.light.primary"
        ></apexchart>
      </div>
    </v-card-text>

    <v-card-text class="mt-2">
      <div
        v-for="(contrate,index) in salesMonth.contratesHotel"
        :key="contrate.contrate_id"
        :class="`statistics-table d-flex align-center pt-3 ${index > 0 ? 'mt-3':''}`"
      >
        <!--<span :class="`badge-sm ${data.color}`"></span>-->
        <span class="text--primary ms-2">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span
                v-bind="attrs"
                v-on="on"
              >{{ contrate.identificador | truncate(50) }}</span>
            </template>
            <span>
              <b>{{ $t('lbl.identificador') }}</b>: {{ contrate.identificador }}
              <br />
              <b>{{ $t('lbl.proveedor') }}</b>:
              <span v-if="user.nivel <= 0">
                <span v-if="contrate.operador === 1111">
                  <span v-if="contrate.proveedor_id != null">
                    {{ contrate.proveedor }}
                  </span>
                </span>
                <span v-else>
                  {{ contrate.operador }}
                </span>
              </span>
              <span v-else>
                <span v-if="contrate.proveedor_id != null">
                  {{ contrate.proveedor }}
                </span>
              </span>
              <br />
              <b>{{ $t('lbl.operador') }}</b>:
              <br v-if="contrate.operators.length > 1" />
              <span>
                <span
                  v-for="(operator, indO) in contrate.operators"
                  :key="indO"
                >
                  {{ operator }} <br v-if="indO !== contrate.operators.length - 1" />
                </span>
              </span>
              <br />
              <b>{{ $t('dashboard.source') }}</b>:
              <span v-if="contrate.source.length === 0">
                {{ $t('dashboard.direct') }}
              </span>
              <span v-else>
                <span
                  v-for="(sss, indS) in contrate.source"
                  :key="indS"
                >
                  {{ getSourceByOne(contrate) }}
                </span>
              </span>
            </span>
          </v-tooltip>
        </span>

        <v-spacer></v-spacer>

        <span class="font-weight-semibold text--primary me-6">
          {{ contrate.monto | currency(salesMonth.symbol_base) }}
        </span>
        <span class="font-weight-semibold text--primary me-1">
          {{ contrate.pc_sales }}%
        </span>
        <!--<v-icon
          size="20"
          :color="data.percentage.charAt(0) === '+' ? 'success' :'error'"
        >
          {{ data.percentage.charAt(0) === '+' ? icons.mdiChevronUp:icons.mdiChevronDown }}
        </v-icon>-->
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
// eslint-disable-next-line object-curly-newline
import {
  mdiAccountOutline,
  mdiCurrencyUsd,
  mdiTrendingUp,
  mdiDotsVertical,
  mdiLabelOutline,
  mdiChevronUp,
  mdiChevronDown,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    user: {
      type: Object,
    },
  },
  data() {
    return {
      icons: {
        mdiAccountOutline,
        mdiCurrencyUsd,
        mdiTrendingUp,
        mdiDotsVertical,
        mdiLabelOutline,
        mdiChevronUp,
        mdiChevronDown,
      },
      isLoading: true,
      chartOptions: {},
      series: [],
    }
  },
  computed: {
    ...mapState({
      salesMonth: state => state.app.salesMonth,
    }),
  },
  mounted() {
    this.series.push({
      data: [50, 40, 110, 80, 40, 60, 110],
    })
    this.chartOptions = {
      colors: [this.$vuetify.theme.currentTheme.primary],
      chart: {
        type: 'bar',
        toolbar: {
          show: false,
        },
        sparkline: {
          enabled: true,
        },
      },
      grid: {
        show: false,
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        labels: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        show: false,
      },

      tooltip: {
        x: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '40%',
          startingShape: 'rounded',
          endingShape: 'rounded',
          borderRadius: 4,
        },
      },
    }
    this.isLoading = false
  },
  methods: {
    getSourceByOne(contrate) {
      let result = 'Directo'
      if (contrate.source.includes('hotetec')) {
        result = 'Hotetec'
      } else if (contrate.source.includes('dingus')) {
        result = 'Dingus'
      } else if (contrate.source.includes('solways')) {
        result = 'Solways'
      }

      return result
    },
  },
}
</script>
<style lang="scss" scoped>
.chart-wrapper {
  max-width: 130px;
}
.statistics-table {
  border-top: solid 1px rgba(93, 89, 98, 0.14);
  .badge-sm {
    width: 0.875rem;
    height: 0.875rem;
    border-radius: 50%;
    margin: 4px;
  }
}
</style>
