<!-- eslint-disable vue/no-v-html -->
<template>
  <v-card
    v-if="showCardIdentificadorInfo ||
      showCardIdentificadorBanco ||
      showCardIdentificadorBooking ||
      showCardIdentificadorIden"
  >
    <v-tabs
      v-model="tab"
      class="tab-index"
    >
      <v-tab>
        {{ $t('lbl.infoFiscal') }}
      </v-tab>
      <v-tab>
        {{ $t('lbl.bancaria') }}
      </v-tab>
      <v-tab>
        {{ $t('menu.reservas') }}
      </v-tab>
      <v-tab>
        {{ $t('lbl.identificador') }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item
        class="mt-2"
      >
        <v-card-title>
          {{ $t('lbl.infoFiscal') }}
        </v-card-title>
        <v-card-text>
          {{ $t('dashboard.cardIdentificador1') }}
        </v-card-text>

        <v-card-text>
          <v-row>
            <v-col cols="2">
              <v-btn
                color="primary"
                :disabled="!showCardIdentificadorInfo"
                @click="goUrl()"
              >
                {{ $t('btn.complete') }}
              </v-btn>
            </v-col>
            <v-col cols="8"></v-col>
            <v-col cols="2">
              <v-btn
                :color="showCardIdentificadorInfo ? 'error' : 'success'"
                fab
                icon
              >
                <v-icon>
                  mdi-check-outline
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-tab-item>
      <v-tab-item
        class="mt-2"
      >
        <v-card-title>
          {{ $t('lbl.bancaria') }}
        </v-card-title>
        <v-card-text>
          {{ $t('dashboard.cardIdentificador2') }}
        </v-card-text>

        <v-card-text>
          <v-row>
            <v-col cols="2">
              <v-btn
                :disabled="!showCardIdentificadorBanco"
                color="primary"
                @click="goUrl()"
              >
                {{ $t('btn.complete') }}
              </v-btn>
            </v-col>
            <v-col cols="8"></v-col>
            <v-col cols="2">
              <v-btn
                :color="showCardIdentificadorBanco ? 'error' : 'success'"
                fab
                icon
              >
                <v-icon>
                  mdi-check-outline
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-tab-item>
      <v-tab-item
        class="mt-2"
      >
        <v-card-title>
          {{ $t('menu.reservas') }}
        </v-card-title>
        <v-card-text>
          {{ $t('dashboard.cardIdentificador3') }}
        </v-card-text>

        <v-card-text>
          <v-row>
            <v-col cols="2">
              <v-btn
                :disabled="!showCardIdentificadorBooking"
                color="primary"
                @click="goUrl()"
              >
                {{ $t('btn.complete') }}
              </v-btn>
            </v-col>
            <v-col cols="8"></v-col>
            <v-col cols="2">
              <v-btn
                :color="showCardIdentificadorBooking ? 'error' : 'success'"
                fab
                icon
              >
                <v-icon>
                  mdi-check-outline
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-tab-item>
      <v-tab-item
        class="mt-2"
      >
        <v-card-title>
          {{ $t('lbl.identificador') }}
        </v-card-title>
        <v-card-text>
          {{ $t('dashboard.cardIdentificador4') }}
        </v-card-text>

        <v-card-text>
          <v-row>
            <v-col cols="2">
              <v-btn
                :disabled="!showCardIdentificadorIden"
                color="primary"
                @click="goUrl()"
              >
                {{ $t('btn.complete') }}
              </v-btn>
            </v-col>
            <v-col cols="8"></v-col>
            <v-col cols="2">
              <v-btn
                :color="showCardIdentificadorIden ? 'error' : 'success'"
                fab
                icon
              >
                <v-icon>
                  mdi-check-outline
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
// eslint-disable-next-line object-curly-newline
import {
  mdiAccountOutline,
  mdiCurrencyUsd,
  mdiTrendingUp,
  mdiDotsVertical,
  mdiLabelOutline,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    user: {
      type: Object,
    },
  },
  data() {
    return {
      icons: {
        mdiAccountOutline,
        mdiCurrencyUsd,
        mdiTrendingUp,
        mdiDotsVertical,
        mdiLabelOutline,
      },
      tab: 0,
    }
  },
  computed: {
    ...mapState({
      showCardIdentificadorInfo: state => state.app.showCardIdentificadorInfo,
      showCardIdentificadorBanco: state => state.app.showCardIdentificadorBanco,
      showCardIdentificadorBooking: state => state.app.showCardIdentificadorBooking,
      showCardIdentificadorIden: state => state.app.showCardIdentificadorIden,
    }),
  },
  methods: {
    ...mapMutations([
      'setPosCotizador',
    ]),
    goUrl() {
      this.setPosCotizador(this.tab)
      this.$router.push({ name: 'configuration-general' })
    },
  },
}
</script>
<style lang="scss" scoped>
.tab-index {
  position: relative;
  z-index: 1;
}
</style>
